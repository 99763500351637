import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 
import authReducer from '../V1/Modules/D5art/RMS/Slice/authSlice';
import registerReducer from '../V1/Modules/D5art/RMS/Slice/authSlice';
import coursesReducer from '../V1/Modules/D5art/LMS/Slice/coursesSlice';
import seekerAssessmentDashboardReducer from "../V1/Modules/D5art/LMS/Slice/assessmentDashboardSlice"
import assessmentResultReducer from '../V1/Modules/D5art/RMS/Slice/assessmentResultSlice';
import cxoAuthReducer from '../V1/Modules/CXO/Slice/cxoAuthSlice';
import userProfileReducer from '../V1/Modules/CXO/Slice/profileSlice';
import adminCxoUsersReducer from '../V1/Modules/CXO/Slice/adminCxoUsersSlice';
import adminCxoOrganizationReducer from '../V1/Modules/CXO/Slice/adminCxoOrganizationSlice';
import cxoConsultationReducer from '../V1/Modules/CXO/Slice/cxoConsultationSlice';
import cxoRevenueReducer from '../V1/Modules/CXO/Slice/cxoRevenueSlice';


const rootReducer = combineReducers({
  auth: authReducer,
  register:registerReducer,
  courses : coursesReducer,
  seekerAssessmentDashboard:seekerAssessmentDashboardReducer,
  assessmentResult:assessmentResultReducer,

  cxoAuth: cxoAuthReducer,
  userProfile: userProfileReducer,
  adminCxoUsers:adminCxoUsersReducer,
  adminCxoOrganization:adminCxoOrganizationReducer,
  cxoConsultation:cxoConsultationReducer,
  cxoRevenue:cxoRevenueReducer,
});

const persistConfig = {
  key: 'root', 
  storage,
   whitelist: ['auth',"cxoAuth"], 
  // blacklist: ['auth'], 
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
