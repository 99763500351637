import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import axiosInstance from '../../../../../store/axiosInstance';
import { SERVER_URL } from '../../../../../Config/config';


const clearBrowserData = () => {
  localStorage.clear();
  sessionStorage.clear();
  document.cookie.split(";").forEach((cookie) => {
    document.cookie = cookie.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
  console.log("Browser data cleared successfully");
};


export const loginUser = createAsyncThunk('auth/loginUser', async (credentials, { rejectWithValue,dispatch }) => {
  try {
    clearBrowserData()
    const response = await axios.post(`${SERVER_URL}/api/v1/auth/login`, credentials, {
      withCredentials: true, 
    });
    dispatch(resetState());
    console.log("response",response)
    toast.success('Welcome to D⁵ART!');
    return response.data; 
  } catch (error) {
    console.log("error",error)
    toast.error(error.response?.data?.error || 'Login failed');
    return rejectWithValue(error.response?.data);
  }
});

export const googleLogin = createAsyncThunk(
  'auth/googleLogin',
  async (userData, { rejectWithValue }) => {
    try {
      clearBrowserData()
      const response = await axios.post(`${SERVER_URL}/api/v1/auth/google-login`, userData, {
        withCredentials: true,
      });

      toast.success('Google login successful');
      return response.data.user;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Google login failed';
      toast.error(errorMessage);
      return rejectWithValue(errorMessage); 
    }
  }
);

export const walletLogin = createAsyncThunk(
  'auth/walletLogin',
  async (walletAddress, { rejectWithValue }) => {
    try {
      clearBrowserData()
      const response = await axios.post(`${SERVER_URL}/api/v1/auth/wallet-login`, { walletAddress }, {
        withCredentials: true,
      });

      toast.success('Wallet login successful');
      return response.data.user;
    } catch (error) {
      toast.error(error.response?.data?.message || 'Wallet login failed');
      return rejectWithValue(error.response?.data);
    }
  }
);

export const refreshAccessToken = createAsyncThunk('auth/refreshAccessToken', async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post('/api/v1/auth/refresh-token');
    return response.data; 
  } catch (error) {
    return rejectWithValue(error.response?.data);
  }
});

export const logout = createAsyncThunk('auth/logoutUser', async (_, { dispatch }) => {
  clearBrowserData();
  dispatch(resetState());
  toast.info('Logged out successfully');
});

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    accessToken: null,
    loading: false,
    error: null,
  },
  reducers: {
    // logout: (state) => {
    //   state.user = null;
    //   state.accessToken = null;
    //   toast.info('Logged out successfully');
    // },

    resetState: (state) => {
      state.user = null;
      state.accessToken = null;
      state.loading = false;
      state.error = null;
    },
    updateUserStep: (state, action) => {
      if (state.user) {
        state.user.step = action.payload; 
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.accessToken = action.payload.accessToken;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(refreshAccessToken.fulfilled, (state, action) => {
        state.accessToken = action.payload.accessToken;
        state.loading = false;
      })
      .addCase(googleLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(googleLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(googleLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(walletLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(walletLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(walletLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetState,updateUserStep } = authSlice.actions;
export default authSlice.reducer;
