import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export default function Nextbutton({ onClick, text }) {
    return (
        <button className='flex items-center justify-center' onClick={onClick}>
            <h1 className='text-white absolute text-lg'>{text} <span className='text-sm ml-2'><FontAwesomeIcon icon={faChevronRight }/><FontAwesomeIcon icon={faChevronRight }/></span></h1>
            <svg width="full" height="77" viewBox="0 0 512 77" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M510 6.11211L503.285 1.2018H477.036L66.9771 1L49.2022 5.91031L2 72.5022L9.30746 76H443.443L461.02 72.9731L510 6.11211Z" fill="#51CBFF" fill-opacity="0.66" stroke="#B8F1A9" stroke-width="2" />
                <rect x="185" y="71" width="129" height="6" fill="black" />
                <rect x="185" width="129" height="6" fill="black" />
            </svg>
        </button>

    )
}
