export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const GOOGLE_LOGIN_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const REACT_APP_RAZORPAY_KEY_ID = process.env.REACT_APP_RAZORPAY_KEY_ID;
export const G_TRACKING_ID = process.env.REACT_APP_G_TRACKING_ID;
export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const useCloudflare = process.env.REACT_APP_RECAPTCHA;
export const WALLET_PROJECT_ID = process.env.REACT_APP_WALLET_PROJECT_ID;
export const ENCRYPT_SECRET_KEY = process.env.REACT_APP_ENCRYPT_SECRET_KEY;
export const SEEKER_SECRETKEY = process.env.REACT_APP_SEEKER_SECRETKEY;
export const RECRUITER_SECRETKEY = process.env.REACT_APP_RECRUITER_SECRETKEY;
export const POST_ASSESSMENT = "postassessment"
export const PRE_ASSESSMENT = "preassessment"
export const SKILL_ASSESSMENT = "skillassessment"
