import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { SERVER_URL } from "../../../../Config/config";

export const fetchCxoRevenue = createAsyncThunk("cxoRevenue/fetchAll", async (_, thunkAPI) => {
  try {
    const response = await axios.get(`${SERVER_URL}/api/admin/cxo/revenue`);
    return response.data.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || "Failed to fetch data");
  }
});


const cxoRevenueSlice = createSlice({
  name: "cxoRevenue",
  initialState: { data: [], loading: false, error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCxoRevenue.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCxoRevenue.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchCxoRevenue.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default cxoRevenueSlice.reducer;
