import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../../store/axiosInstance";
import { SERVER_URL } from "../../../../../Config/config";

export const fetchseekerAssessmentDashboard = createAsyncThunk(
  "seeker/fetchseekerAssessmentDashboard",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`${SERVER_URL}/api/v1/answerset/get-dashboard`);
      console.log("fetchseekerAssessmentDashboardresponse",response)
      return response.data.data;
    } catch (error) {
        console.log(" fetchseekerAssessmentDashboard error",error)
      return rejectWithValue(error.response?.data?.message || "Something went wrong");
    }
  }
);

const seekerAssessmentDashboardSlice = createSlice({
  name: "seekerAssessmentDashboard",
  initialState: {
    courses: [],
    progress: [],
    certificates: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchseekerAssessmentDashboard.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchseekerAssessmentDashboard.fulfilled, (state, action) => {
        state.loading = false;
        state.courses = action.payload.courses;
        state.progress = action.payload.progress;
        state.certificates = action.payload.certificates;
      })
      .addCase(fetchseekerAssessmentDashboard.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default seekerAssessmentDashboardSlice.reducer;
