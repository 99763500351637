import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { SERVER_URL } from '../../../../Config/config';

export const loginUser = createAsyncThunk(
  "cxoAuth/loginUser",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${SERVER_URL}/api/v1/auth/login`, credentials);

      toast.success("Login successful 🎉");
      console.log("response",response)
      return response.data;
    } catch (error) {
      console.error("Login error:", error);

      const errorMessage = error.response?.data?.message || "Login failed. Please try again.";
      toast.error(errorMessage);

      return rejectWithValue(error.response?.data || { message: errorMessage });
    }
  }
);

export const googleLogin = createAsyncThunk(
  'auth/googleLogin',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${SERVER_URL}/api/v1/auth/google-login`, userData, {
        withCredentials: true,
      });

      toast.success('Google login successful');
      return response.data.user;
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Google login failed';
      toast.error(errorMessage);
      return rejectWithValue(errorMessage); 
    }
  }
);

export const refreshAccessToken = createAsyncThunk('auth/refreshAccessToken', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.post('/api/v1/auth/refresh-token');
    return response.data; 
  } catch (error) {
    return rejectWithValue(error.response?.data);
  }
});

const cxoAuthSlice = createSlice({
  name: 'cxoAuth',
  initialState: {
    user: [],
    accessToken: null,
    loading: false,
    error: null,
  },
  reducers: {
    logout: (state) => {
      state.user = null;
      state.accessToken = null;
      toast.success('Logged out successfully');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        console.log("action.payload.use",action)
        state.accessToken = action.payload.accessToken;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(refreshAccessToken.fulfilled, (state, action) => {
        state.accessToken = action.payload.accessToken;
      })
      .addCase(googleLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(googleLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(googleLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { logout } = cxoAuthSlice.actions;
export default cxoAuthSlice.reducer;
