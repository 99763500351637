import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../../store/axiosInstance";
import { POST_ASSESSMENT, SERVER_URL } from "../../../../../Config/config";



export const fetchAssessmentResults = createAsyncThunk(
  "assessment/fetchAssessmentResults",
  async (_, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.get(`${SERVER_URL}/api/v1/answerset/get-assessment-results`);
      console.log("API Response:", response);

      


      const groupedCourses = {};

      response.data.forEach((assessment) => {
        const { course_id, assessment: type, percentage, createdAt, course_name } = assessment;

        if (!groupedCourses[course_id]) {
          groupedCourses[course_id] = {
            course_id,
            course_name: course_name || "Unknown Course",
            pre_assessments: [],
            post_assessments: [],
            selectedPre: null,
            selectedPost: null,
            pre_percentage: "-",
            post_percentage: "-",
            percentage_raised: "-",
            start_date: "-",
            end_date: "-",
          };
        }

        if (type?.toLowerCase() === POST_ASSESSMENT ) {
          groupedCourses[course_id].pre_assessments.push({ percentage, createdAt });
        } else if (type?.toLowerCase() === POST_ASSESSMENT ) {
          groupedCourses[course_id].post_assessments.push({ percentage, createdAt });
        }
      });

      return Object.values(groupedCourses).map((course) => {
        course.pre_assessments.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        course.post_assessments.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        course.selectedPre = course.pre_assessments.length > 0 ? course.pre_assessments[0] : null;
        course.selectedPost = course.post_assessments.length > 0 ? course.post_assessments[0] : null;
        course.pre_percentage = course.selectedPre ? parseFloat(course.selectedPre.percentage).toFixed(2) : "-";
        course.post_percentage = course.selectedPost ? parseFloat(course.selectedPost.percentage).toFixed(2) : "-";
        course.percentage_raised =
          course.selectedPre && course.selectedPost
            ? (parseFloat(course.selectedPost.percentage) - parseFloat(course.selectedPre.percentage)).toFixed(2)
            : "-";

        course.start_date = course.pre_assessments.length > 0 ? course.pre_assessments[0].createdAt.split("T")[0] : "-";
        course.end_date =
          course.post_assessments.length > 0
            ? course.post_assessments[course.post_assessments.length - 1].createdAt.split("T")[0]
            : "-";

        return course;
      });
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching data");
    }
  }
);

const assessmentResultSlice = createSlice({
  name: "assessmentResul",
  initialState: {
    courses: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    updateSelectedPre: (state, action) => {
      const { courseId, selectedPre } = action.payload;
      state.courses = state.courses.map((course) =>
        course.course_id === courseId
          ? {
              ...course,
              selectedPre,
              pre_percentage: selectedPre.percentage,
              percentage_raised:
                course.selectedPost && selectedPre
                  ? (parseFloat(course.selectedPost.percentage) - parseFloat(selectedPre.percentage)).toFixed(2)
                  : "-",
            }
          : course
      );
    },
    updateSelectedPost: (state, action) => {
      const { courseId, selectedPost } = action.payload;
      state.courses = state.courses.map((course) =>
        course.course_id === courseId
          ? {
              ...course,
              selectedPost,
              post_percentage: selectedPost.percentage,
              percentage_raised:
                selectedPost && course.selectedPre
                  ? (parseFloat(selectedPost.percentage) - parseFloat(course.selectedPre.percentage)).toFixed(2)
                  : "-",
              end_date: selectedPost ? selectedPost.createdAt.split("T")[0] : "-",
            }
          : course
      );
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAssessmentResults.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAssessmentResults.fulfilled, (state, action) => {
        state.isLoading = false;
        state.courses = action.payload;
      })
      .addCase(fetchAssessmentResults.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { updateSelectedPre, updateSelectedPost } = assessmentResultSlice.actions;
export default assessmentResultSlice.reducer;
