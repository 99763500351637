
import React, { createContext, useState, useEffect } from "react";

export const CurrencyContext = createContext();




const countryToCurrency = {
  US: "USD",
  IN: "INR",
  GB: "GBP",
  CA: "CAD",
  AU: "AUD",
  JP: "JPY",
  CN: "CNY",
  AE: "AED",
  BH: "BHD",
  CH: "CHF",
  KW: "KWD",
  MY: "MYR",
  NZ: "NZD",
  OM: "OMR",
  QA: "QAR",
  SA: "SAR",
  SG: "SGD",
  TH: "THB",
  ZA: "ZAR",
  DE: "EUR",
  FR: "EUR",
  IT: "EUR",
  ES: "EUR",
  NL: "EUR",
  BE: "EUR",
  AT: "EUR",
  IE: "EUR",
  FI: "EUR",
  PT: "EUR",
  GR: "EUR",
};

const supportedCurrencies = [
  "USD",
  "INR",
  "GBP",
  "CAD",
  "AUD",
  "JPY",
  "CNY",
  "AED",
  "BHD",
  "CHF",
  "KWD",
  "MYR",
  "NZD",
  "OMR",
  "QAR",
  "SAR",
  "SGD",
  "THB",
  "ZAR",
  "EUR",
];

const CurrencyProvider = ({ children }) => {
  const [currency, setCurrency] = useState("USD");
  const [exchangeRates, setExchangeRates] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCurrencyData = async () => {
      try {
        let countryCode = sessionStorage.getItem("userCountry");

        if (!countryCode) {
          const locationRes = await fetch("https://ipapi.co/json/");
          if (!locationRes.ok) throw new Error("Failed to fetch location");
          const locationData = await locationRes.json();
          countryCode = locationData.country_code || "US";

          sessionStorage.setItem("userCountry", countryCode);
        }

        const validCurrency = countryToCurrency[countryCode] || "USD";
        setCurrency(validCurrency);

        let storedRates = sessionStorage.getItem("exchangeRates");
        if (storedRates) {
          setExchangeRates(JSON.parse(storedRates));
        } else {
          const exchangeRes = await fetch("https://open.er-api.com/v6/latest/USD");
          if (!exchangeRes.ok) throw new Error("Failed to fetch exchange rates");
          const exchangeData = await exchangeRes.json();

          //setExchangeRates(exchangeData.rates);
        //  sessionStorage.setItem("exchangeRates", JSON.stringify(exchangeData.rates));
        const filteredRates = Object.fromEntries(
          Object.entries(exchangeData.rates).filter(([currency]) =>
            supportedCurrencies.includes(currency)
          )
        );
        setExchangeRates(filteredRates);
        sessionStorage.setItem("exchangeRates", JSON.stringify(filteredRates));
        }
      } catch (error) {
        console.error("Error fetching currency data:", error);
        setCurrency("USD");
        setExchangeRates({ USD: 1 });
      } finally {
        setLoading(false);
      }
    };

    fetchCurrencyData();
  }, []);

  const handleCurrencyChange = (newCurrency) => {
    if (supportedCurrencies.includes(newCurrency)) {
      setCurrency(newCurrency);
    } else {
      console.warn(`Currency ${newCurrency} is not supported by Razorpay.`);
    }
  };
  return (
    <CurrencyContext.Provider value={{ currency, exchangeRates, handleCurrencyChange, loading }}>
      {children}
    </CurrencyContext.Provider>
  );
};

export default CurrencyProvider;
