// import React from "react";
// import { Routes, Route } from "react-router-dom";
// /*Auth*/
// import SeekerLogin from "../V1/Modules/D5art/Auth/Login/SeekerLogin";
// import RecruiterLogin from "../V1/Modules/D5art/Auth/Login/RecruiterLogin";
// import ValidatorLogin from "../V1/Modules/D5art/Auth/Login/ValidatorLogin";

// /*Registraiton*/
// import Seeker_Register from "../V1/Modules/D5art/RMS/Components/Auth/Seeker_Register";

// /*Seeker Pages*/
// import Home from "../V1/Modules/D5art/RMS/Components/Pages/Home/Home";
// import SkillArenalist from "../V1/Modules/D5art/RMS/Components/Pages/Seeker/Seeker_SkillArenalist/SkillArenalist";
// import Skillmarket from "../V1/Modules/D5art/RMS/Components/Pages/Seeker/seeker_Skillmarket/Skillmarket";
// import SeekerUserDashboard from "../V1/Modules/D5art/RMS/Components/Pages/Seeker/UserDashboard/Dashboard/UserDashboard";
// import Profileupdate from "../V1/Modules/D5art/RMS/Components/Pages/Seeker/Profileupdate/Profileupdate";
// import FullProfile from "../V1/Modules/D5art/RMS/Components/Pages/Seeker/UserDashboard/Pages/FullProfile/FullProfile";
// import Congrats from "../V1/Modules/D5art/RMS/Components/Pages/Seeker/Profileupdate/Congrats";

// /*Recruiter Pages*/
// import RecruiterHome from "../V1/Modules/D5art/RMS/Components/Pages/Recruiter/Recruiter_Home/Recruiter_Home";
// import RecruiterSkillArenalist from "../V1/Modules/D5art/RMS/Components/Pages/Recruiter/Recruiter_SkillArenalist/SkillArenalist";
// import RecruiterSkillmarket from "../V1/Modules/D5art/RMS/Components/Pages/Recruiter/Recruiter_Skillmarket/Skillmarket";
// import RecruiterUserDashboard from "../V1/Modules/D5art/RMS/Components/Pages/Recruiter/Recruiter_UserDashboard/Dashboard/UserDashboard";


// /*Validator Pages*/

// /*Courses*/
// import Courses from "../V1/Modules/D5art/LMS/Components/Pages/ExploreCourses/Courses";
// import CoursePreview from "../V1/Modules/D5art/LMS/Components/Pages/CoursePreview/CoursePreview";
// import CourseVideo from "../V1/Modules/D5art/LMS/Components/Pages/CourseVideo/CourseVideo";
// import PreAssessment from "../V1/Modules/D5art/LMS/Components/Pages/Assessment/PreAssessment";

// /*Common*/
// import Faq from "../V1/Modules/D5art/RMS/Components/Pages/Faq/Faq";
// import Jordanhome from "../V1/Modules/D5art/Landing/JordanEvent/Pages/Home";
// import Dnahome from "../V1/Modules/D5art/Landing/DNA/DnaHome";
// import EventAssessment from "../V1/Modules/D5art/Landing/JordanEvent/Pages/Assessment";
// import JordanResult from "../V1/Modules/D5art/Landing/JordanEvent/Pages/AssessmentResult/Result";
// import ProtectedRoute from "./ProtectedRoute";


// // CXO
// import CXOHome from "../V1/Modules/CXO/Pages/Landing/Home";
// import CXODna from "../V1/Modules/CXO/Pages/DNA/Dna";
// import CXODashboard from "../V1/Modules/CXO/Pages/Dashboard/Dashboard";
// import CXOCareerhub from "../V1/Modules/CXO/Pages/Careerhub/Careerhub";
// import CXOLogin from "../V1/Modules/CXO/Login/Login";
// import Userdata from "../V1/Modules/CXO/Adminpanel/Pages/Userdata";
// import Group from "../V1/Modules/CXO/Adminpanel/Pages/Group";
// import Consultation from "../V1/Modules/CXO/Adminpanel/Pages/Consultation";
// import Revenue from "../V1/Modules/CXO/Adminpanel/Pages/Revenue";
// import Ticket from "../V1/Modules/CXO/Pages/Ticket";













// // import Sample from "../Sample";

// import SeekerRegister from "../V1/Modules/D5art/Auth/Register/SeekerRegister";
// import RecruiterRegister from "../V1/Modules/D5art/Auth/Register/RecruiterRegister";
// import ValidatorRegister from "../V1/Modules/D5art/Auth/Register/ValidatorRegister";
// import AssessmentResult from "../V1/Modules/D5art/RMS/Components/Pages/Seeker/UserDashboard/Pages/AssessmentResult";
// import NftCertificate from "../V1/Modules/D5art/RMS/Components/Pages/Seeker/UserDashboard/Pages/NftCertificate";


// const AppRoutes = () => {
//     return (

//         <Routes>
//             {/*Auth*/}
//             <Route path="/seeker-login" element={<SeekerLogin />} />
//             <Route path="/recruiter-login" element={<RecruiterLogin />} />
//             <Route path="/validator-login" element={<ValidatorLogin />} />


//             {/*Registraiton*/}
//             <Route path='/seeker-register1' element={<Seeker_Register />} />
//             <Route path="/seeker-register" element={<SeekerRegister/>} />
//             <Route path="/recruiter-register" element={<RecruiterRegister/>} />
//             <Route path="/validator-register" element={<ValidatorRegister/>} />



//             {/*Courses*/}
//             <Route path="/courses" element={<Courses />} />
//             <Route path="/pre-course/:course_name/:course_id" element={<CoursePreview />} />
//             <Route path="/course-play/:course_name/:course_id" element={<CourseVideo />} />
//             <Route path="/nft-certificate/:tokenid" element={<NftCertificate />} />
//             <Route path="/pre-assessment" element={<PreAssessment />} />


//             {/* <Route path="/pre-course" element={<CoursePreview/>} /> */}




//             {/*Seeker Pages*/}
//             <Route path="/" element={<Home />} />
//             <Route path="/seeker/skillarenalist" element={<SkillArenalist />} />
//             <Route path="/seeker/skillmarket" element={<Skillmarket />} />
//             <Route path="/seeker/profile-update" element={<Profileupdate />} />
//             <Route path="/seeker/dashboard" element={<SeekerUserDashboard />} />
//             <Route path="/fullprofile" element={<FullProfile />} />
//             <Route path="/Congrats" element={<Congrats />} />
//             <Route path="/seeker/assessment-result" element={<AssessmentResult />} />

//             {/* <Route path="/sample" element={<Sample />} /> */}

//             {/*Recruiter Pages*/}
//             <Route path="/recruiter" element={<RecruiterHome />} />
//             <Route path="/recruiter/skillarenalist" element={<RecruiterSkillArenalist />} />
//             <Route path="/recruiter/skillmarket" element={<RecruiterSkillmarket />} />
//             <Route path="/recruiter/dashboard" element={<RecruiterUserDashboard />} />

//             {/*Validator Pages*/}

//             {/*Common*/}
//             <Route path="/faq" element={<Faq />} />
//             <Route path="/jordan-event" element={<Jordanhome />} />
//             <Route path="/dna-home" element={<Dnahome />} />
//             <Route path="/event/start-assessment" element={<EventAssessment />} />
//             <Route path="/jordan-event/assessment-result" element={<JordanResult />} />


//             {/* CXO */}
//             <Route path="/CXO/login" element={<CXOLogin />} />
//           <Route path="/CXO" element={<ProtectedRoute role="cxo"><CXOHome /></ProtectedRoute>} />
//           <Route path="/CXO/dashboard" element={<ProtectedRoute role="cxo"><CXODashboard /></ProtectedRoute>} />
//           <Route path="/CXO/career-hub" element={<ProtectedRoute role="cxo"><CXOCareerhub /></ProtectedRoute>} />
//           <Route path="/CXO/dna-ai-hi" element={<ProtectedRoute role="cxo"><CXODna /></ProtectedRoute>} />
//           <Route path="/CXO/ticket" element={<ProtectedRoute role="cxo"><Ticket /></ProtectedRoute>} />
//           <Route path="/CXO/Admin/Userdata" element={<ProtectedRoute role="admin"><Userdata /></ProtectedRoute>} />
//           <Route path="/CXO/Admin/Group" element={<ProtectedRoute role="admin"><Group /></ProtectedRoute>} />
//           <Route path="/CXO/Admin/Consultation" element={<ProtectedRoute role="admin"><Consultation /></ProtectedRoute>} />
//           <Route path="/CXO/Admin/Revenue" element={<ProtectedRoute role="admin"><Revenue /></ProtectedRoute>} />





//         </Routes>
//     );
// };

// export default AppRoutes;









import React, { Suspense, lazy, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Seeker_Register from "../V1/Modules/D5art/RMS/Components/Auth/Seeker_Register";
import Sample from "../Sample";
import CurrencyProvider from "../Context/CurrencyContext";
import Assessment from "../V1/Modules/D5art/Landing/JordanEvent/Pages/Assessment";



const Terms_Condition = lazy(() => import("../V1/Modules/D5art/Landing/T&C/Terms_Condition"));
const Privacy_Policy = lazy(() => import("../V1/Modules/D5art/Landing/T&C/Privacy_Policy"));
const Refund_Policy = lazy(() => import("../V1/Modules/D5art/Landing/T&C/Refund_Policy"));

const SeekerLogin = lazy(() => import("../V1/Modules/D5art/Auth/Login/SeekerLogin"));
const RecruiterLogin = lazy(() => import("../V1/Modules/D5art/Auth/Login/RecruiterLogin"));
const ValidatorLogin = lazy(() => import("../V1/Modules/D5art/Auth/Login/ValidatorLogin"));
const ResetPasswordPage = lazy(() => import("../V1/Modules/D5art/Auth/Login/ResetPasswordPage"));

const SeekerRegister = lazy(() => import("../V1/Modules/D5art/Auth/Register/SeekerRegister"));
const RecruiterRegister = lazy(() => import("../V1/Modules/D5art/Auth/Register/RecruiterRegister"));
const ValidatorRegister = lazy(() => import("../V1/Modules/D5art/Auth/Register/ValidatorRegister"));


const Home = lazy(() => import("../V1/Modules/D5art/RMS/Components/Pages/Home/Home"));
const SkillArenalist = lazy(() => import("../V1/Modules/D5art/RMS/Components/Pages/Seeker/Seeker_SkillArenalist/SkillArenalist"));
const Skillmarket = lazy(() => import("../V1/Modules/D5art/RMS/Components/Pages/Seeker/seeker_Skillmarket/Skillmarket"));
const SeekerUserDashboard = lazy(() => import("../V1/Modules/D5art/RMS/Components/Pages/Seeker/UserDashboard/Dashboard/UserDashboard"));
const Profileupdate = lazy(() => import("../V1/Modules/D5art/RMS/Components/Pages/Seeker/Profileupdate/Profileupdate"));
const FullProfile = lazy(() => import("../V1/Modules/D5art/RMS/Components/Pages/Seeker/UserDashboard/Pages/FullProfile/FullProfile"));
const Congrats = lazy(() => import("../V1/Modules/D5art/RMS/Components/Pages/Seeker/Profileupdate/Congrats"));
const AssessmentResult = lazy(() => import("../V1/Modules/D5art/RMS/Components/Pages/Seeker/UserDashboard/Pages/AssessmentResult"));
const UpSkill = lazy(() => import("../V1/Modules/D5art/RMS/Components/Pages/Seeker/UserDashboard/Pages/UpSkill"));


const RecruiterHome = lazy(() => import("../V1/Modules/D5art/RMS/Components/Pages/Recruiter/Recruiter_Home/Recruiter_Home"));
const RecruiterSkillArenalist = lazy(() => import("../V1/Modules/D5art/RMS/Components/Pages/Recruiter/Recruiter_SkillArenalist/SkillArenalist"));
const RecruiterSkillmarket = lazy(() => import("../V1/Modules/D5art/RMS/Components/Pages/Recruiter/Recruiter_Skillmarket/Skillmarket"));
const RecruiterUserDashboard = lazy(() => import("../V1/Modules/D5art/RMS/Components/Pages/Recruiter/Recruiter_UserDashboard/Dashboard/UserDashboard"));
const Courses = lazy(() => import("../V1/Modules/D5art/LMS/Components/Pages/ExploreCourses/Courses"));
const CoursePreview = lazy(() => import("../V1/Modules/D5art/LMS/Components/Pages/CoursePreview/CoursePreview"));
const CourseVideo = lazy(() => import("../V1/Modules/D5art/LMS/Components/Pages/CourseVideo/CourseVideo"));
const PreAssessment = lazy(() => import("../V1/Modules/D5art/LMS/Components/Pages/Assessment/PreAssessment"));
const NftCertificate = lazy(() => import("../V1/Modules/D5art/RMS/Components/Pages/Seeker/UserDashboard/Pages/NftCertificate"));

const CXOLogin = lazy(() => import("../V1/Modules/CXO/Login/Login"));
const CXOHome = lazy(() => import("../V1/Modules/CXO/Pages/Landing/Home"));
const CXODashboard = lazy(() => import("../V1/Modules/CXO/Pages/Dashboard/Dashboard"));
const CXOCareerhub = lazy(() => import("../V1/Modules/CXO/Pages/Careerhub/Careerhub"));
const Ticket = lazy(() => import("../V1/Modules/CXO/Pages/Ticket"));
const CXODna = lazy(() => import("../V1/Modules/CXO/Pages/DNA/Dna"));
const Userdata = lazy(() => import("../V1/Modules/CXO/Adminpanel/Pages/Userdata"));
const Group = lazy(() => import("../V1/Modules/CXO/Adminpanel/Pages/Group"));
const Consultation = lazy(() => import("../V1/Modules/CXO/Adminpanel/Pages/Consultation"));
const Revenue = lazy(() => import("../V1/Modules/CXO/Adminpanel/Pages/Revenue"));


const Faq = lazy(() => import("../V1/Modules/D5art/RMS/Components/Pages/Faq/Faq"));
const Jordanhome = lazy(() => import("../V1/Modules/D5art/Landing/JordanEvent/Pages/Home"));
const Dnahome = lazy(() => import("../V1/Modules/D5art/Landing/DNA/DnaHome"));
const EventAssessment = lazy(() => import("../V1/Modules/D5art/Landing/JordanEvent/Pages/Assessment"));
const JordanResult = lazy(() => import("../V1/Modules/D5art/Landing/JordanEvent/Pages/AssessmentResult/Result"));


const KeywordSearch = lazy(() => import("../V1/Modules/D5art/Testing/Pages/KeywordSearch"));

///ADMIN
const UserDashboard = lazy(() => import("../V1/Modules/D5art/Admin/UserDashboard"));


// const Loading = () => <div>Loading...</div>;


const AppRoutes = () => {

  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots === '...') {
          return '';
        } else {
          return prevDots + '.';
        }
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const Loading = () => <div className='flex items-center justify-center h-screen bg-black'>
    <div>
      <div className='h-[8rem] w-[12.5rem]'>
        <img src='/assets/Loader/profile_loader.gif' className='w-[75%] mx-auto'></img>
      </div>
      <p className='text-center font-bricolagegrotesque text-sky-300'>Please wait Loading {dots}
      </p>
    </div>
  </div>

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        {/* 🔹 Authentication Routes */}
        <Route path="/seeker-login" element={<SeekerLogin />} />
        <Route path="/recruiter-login" element={<RecruiterLogin />} />
        <Route path="/validator-login" element={<ValidatorLogin />} />
        {/* <Route path="/sample" element={<Sample />} /> */}
        <Route path="/reset-password/:token" element={<ResetPasswordPage />} />


        {/* 🔹 Registration Routes */}
        <Route path='/seeker-register1' element={<Seeker_Register />} />
        <Route path="/seeker-register" element={<SeekerRegister />} />
        <Route path="/recruiter-register" element={<RecruiterRegister />} />
        <Route path="/validator-register" element={<ValidatorRegister />} />

        <Route path="/terms-and-conditions" element={<Terms_Condition />} />
        <Route path="/privacy-policy" element={<Privacy_Policy />} />
        <Route path="/refund-policy" element={<Refund_Policy />} />

        {/*Courses*/}
        <Route path="/courses" element={<CurrencyProvider> <Courses />  </CurrencyProvider>} />
        <Route path="/pre-course/:course_name/:course_id" element={<CurrencyProvider>  <ProtectedRoute allowedRoles={["seeker"]}><CoursePreview /></ProtectedRoute> </CurrencyProvider>} />
        <Route path="/course-play/:course_name/:course_id" element={<ProtectedRoute allowedRoles={["seeker"]}><CourseVideo /></ProtectedRoute>} />
        <Route path="/nft-certificate/:tokenid" element={<ProtectedRoute allowedRoles={["seeker"]}><NftCertificate /></ProtectedRoute>} />
        {/* <Route path="/pre-assessment"element={<ProtectedRoute allowedRoles={["seeker"]}><PreAssessment /></ProtectedRoute>}  /> */}

        <Route path="/assessment/:assessmentType/:id" element={<ProtectedRoute allowedRoles={["seeker"]}><PreAssessment /></ProtectedRoute>} />

        <Route path="/holland-assessment" element={<ProtectedRoute allowedRoles={["seeker"]}><Assessment /></ProtectedRoute>} />


        {/* 🔹 Seeker Protected Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/seeker" element={<ProtectedRoute allowedRoles={["seeker"]}><Home /></ProtectedRoute>} />
        <Route path="/seeker/skillarenalist" element={<ProtectedRoute allowedRoles={["seeker"]}><SkillArenalist /></ProtectedRoute>} />
        <Route path="/seeker/skillmarket" element={<ProtectedRoute allowedRoles={["seeker"]}><Skillmarket /></ProtectedRoute>} />
        <Route path="/seeker/profile-update" element={<ProtectedRoute allowedRoles={["seeker"]}><Profileupdate /></ProtectedRoute>} />
        <Route path="/seeker/dashboard" element={<ProtectedRoute allowedRoles={["seeker"]}><SeekerUserDashboard /></ProtectedRoute>} />
        <Route path="/fullprofile" element={<ProtectedRoute allowedRoles={["seeker", "recruiter"]}><FullProfile /></ProtectedRoute>} />
        <Route path="/Congrats" element={<ProtectedRoute allowedRoles={["seeker"]}><Congrats /></ProtectedRoute>} />
        <Route path="/seeker/assessment-result" element={<ProtectedRoute allowedRoles={["seeker"]}><AssessmentResult /></ProtectedRoute>} />
        <Route path="/seeker/upskill" element={<ProtectedRoute allowedRoles={["seeker"]}><UpSkill /></ProtectedRoute>} />


        {/* 🔹 Recruiter Protected Routes */}
        <Route path="/recruiter" element={<ProtectedRoute allowedRoles={["recruiter"]}><RecruiterHome /></ProtectedRoute>} />
        <Route path="/recruiter/skillarenalist" element={<ProtectedRoute allowedRoles={["recruiter"]}><RecruiterSkillArenalist /></ProtectedRoute>} />
        <Route path="/recruiter/skillmarket" element={<ProtectedRoute allowedRoles={["recruiter"]}><RecruiterSkillmarket /></ProtectedRoute>} />
        <Route path="/recruiter/dashboard" element={<ProtectedRoute allowedRoles={["recruiter"]}><RecruiterUserDashboard /></ProtectedRoute>} />

        {/* 🔹 CXO Protected Routes */}
        <Route path="/CXO/login" element={<CXOLogin />} />
        <Route path="/CXO" element={<ProtectedRoute allowedRoles={["cxo"]}><CXOHome /></ProtectedRoute>} />
        <Route path="/CXO/dashboard" element={<ProtectedRoute allowedRoles={["cxo"]}><CXODashboard /></ProtectedRoute>} />
        <Route path="/CXO/careerhub" element={<ProtectedRoute allowedRoles={["cxo"]}><CXOCareerhub /></ProtectedRoute>} />
        <Route path="/CXO/ticket" element={<ProtectedRoute allowedRoles={["cxo"]}><Ticket /></ProtectedRoute>} />

        {/* 🔹 Common Routes */}
        <Route path="/faq" element={<Faq />} />
        <Route path="/event/start-assessment" element={<EventAssessment />} />
        <Route path="/jordan-event" element={<Jordanhome />} />
        <Route path="/dna-home" element={<Dnahome />} />
        <Route path="/holland/assessment-result" element={<ProtectedRoute allowedRoles={["seeker"]}><JordanResult /></ProtectedRoute>} />


        {/* CXO */}
        <Route path="/CXO/login" element={<CXOLogin />} />
        <Route path="/CXO" element={<ProtectedRoute role="cxo"><CXOHome /></ProtectedRoute>} />
        <Route path="/CXO/dashboard" element={<ProtectedRoute role="cxo"><CXODashboard /></ProtectedRoute>} />
        <Route path="/CXO/career-hub" element={<ProtectedRoute role="cxo"><CXOCareerhub /></ProtectedRoute>} />
        <Route path="/CXO/dna-ai-hi" element={<ProtectedRoute role="cxo"><CXODna /></ProtectedRoute>} />
        <Route path="/CXO/ticket" element={<ProtectedRoute role="cxo"><Ticket /></ProtectedRoute>} />
        <Route path="/CXO/Admin/Userdata" element={<ProtectedRoute role="admin"><Userdata /></ProtectedRoute>} />
        <Route path="/CXO/Admin/Group" element={<ProtectedRoute role="admin"><Group /></ProtectedRoute>} />
        <Route path="/CXO/Admin/Consultation" element={<ProtectedRoute role="admin"><Consultation /></ProtectedRoute>} />
        <Route path="/CXO/Admin/Revenue" element={<ProtectedRoute role="admin"><Revenue /></ProtectedRoute>} />




        {/* ADMIN */}
        <Route path="/admin/dashboard" element={<UserDashboard />} />


        {/* Testing */}
        <Route path="/test/KeywordSearch" element={<KeywordSearch />} />

      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
