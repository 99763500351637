import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import { SERVER_URL } from "../../../../Config/config";

export const fetchCxoUsers = createAsyncThunk("users/fetchCxoUsers", async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${SERVER_URL}/api/admin/cxo/users`);

    if (!response.data.success) {
      throw new Error(response.data.message || "Unknown error from API");
    }

    return response.data;
  } catch (error) {
    console.error("❌ Error fetching users:", error);
    toast.error("Failed to fetch users. Please try again.");
    return rejectWithValue(error.response?.data?.message || error.message || "Error fetching users");
  }
});

export const fetchCxoUserById = createAsyncThunk("users/fetchCxoUserById", async (id, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${SERVER_URL}/api/admin/cxo/users/${id}`);
    
    if (!response.data.success) {
      throw new Error(response.data.message || `Unknown error for user ID ${id}`);
    }

    return response.data;
  } catch (error) {
    console.error(`❌ Error fetching user with ID ${id}:`, error.message);
    toast.error(`Failed to fetch user. Please try again.`);
    return rejectWithValue(error.response?.data?.message || error.message || `Error fetching user ID ${id}`);
  }
});

const adminCxoUsersSlice = createSlice({
  name: "adminCxoUsers",
  initialState: {
    users: [],
    user: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCxoUsers.pending, (state) => {
        state.loading = true;
        state.error = null; // Clear previous errors
      })
      .addCase(fetchCxoUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(fetchCxoUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Use action.payload instead of action.error.message
      })
      .addCase(fetchCxoUserById.pending, (state) => {
        state.loading = true;
        state.error = null; // Clear previous errors
      })
      .addCase(fetchCxoUserById.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(fetchCxoUserById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Use action.payload instead of action.error.message
      });
  },
});

export default adminCxoUsersSlice.reducer;
