import React, { useState } from 'react';
import Header from '../Layout/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import '../Style/style.css';
import { faEnvelope, faEye, faEyeSlash, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import CountryList from '../../../../D5art/Auth/Json/CountryCode.json';
import StateList from '../../../../D5art/Auth/Json/States.json';

export default function Seeker_Register() {
    const [personalInfo, setPersonalInfo] = useState({
        name: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
        country: null,
        state: null,
        dob: '',
        showPassword: false,
        showConfirmPassword: false,
    });

    const customStyles = {
        control: (provided) => ({
            ...provided,
            border: '0px solid #0a3840',
            backgroundColor: 'transparent',
            borderRadius: '7px',
            boxShadow: '0px',
            color: 'white',
            outline: 'none',
            '&:hover': {
                border: '0px solid #0a3840',
            },
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'lightgray',
        }),
        option: (provided) => ({
            ...provided,
            textAlign: 'center',
            backgroundColor: '#090909',
            color: '#fff',
            ':active': {
                backgroundColor: '#d3d3d320',
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#fff',
        }),
        input: (provided) => ({
            ...provided,
            color: '#fff',
        }),
        menu: (provided) => ({
            ...provided,
            background: '#000',
        }),
    };

    const handleInputChange = (key, value) => {
        setPersonalInfo((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const togglePasswordVisibility = (key) => {
        setPersonalInfo((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    return (
        <div className='bg-[#1a1919] bg-cover text-white bg-Fabcbg min-h-screen flex flex-col'>
            <Header />
            <div className='flex flex-1 items-center'>
                <div className='w-full'>
                    <h1 className='text-xl text-center font-bold font-play'>Job Seeker Register</h1>
                    <div className='w-[90%] md:w-[80%] p-5 md:px-10 py-7 bg-white/5 rounded-2xl mt-3 mx-auto'>
                        <h2 className='font-play text-lg font-medium'>Personal information</h2>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-10 mt-3'>
                            <div className='space-y-5'>
                                <div className='flex items-center rounded-lg border border-white/50 bg-white/5'>
                                    <input
                                        className='w-[95%] py-2 2xl:py-4 px-3 bg-transparent outline-none rounded-lg'
                                        placeholder='Enter Your Name'
                                        value={personalInfo.name}
                                        onChange={(e) => handleInputChange('name', e.target.value)}
                                    />
                                    <FontAwesomeIcon icon={faUser} className='w-[5%] px-2' />
                                </div>
                                <div className='flex items-center 2xl:py-2 rounded-lg border border-white/50 bg-white/5'>
                                    <Select
                                        options={CountryList}
                                        placeholder='Country'
                                        styles={customStyles}
                                        value={personalInfo.country}
                                        onChange={(selectedOption) =>
                                            handleInputChange('country', selectedOption)
                                        }
                                        getOptionLabel={(option) =>
                                            `${option.label} (${option.value}) ${option.country_flag}`
                                        }
                                        getOptionValue={(option) => option.label}
                                        className='w-[100%] '
                                    />
                                </div>
                                <div className='flex items-center rounded-lg border border-white/50 bg-white/5'>
                                    <input
                                        className='w-[95%] py-2 2xl:py-4 px-3 bg-transparent outline-none rounded-lg'
                                        placeholder='Enter Your Number'
                                        value={personalInfo.phone}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d*$/.test(value)) {
                                                handleInputChange('phone', value);
                                            }
                                        }}
                                    />
                                    <FontAwesomeIcon icon={faPhone} className='w-[5%] px-2' />
                                </div>
                                <div className='flex items-center rounded-lg border border-white/50 bg-white/5'>
                                    <input
                                        className='w-[95%] py-2 2xl:py-4 px-3 bg-transparent outline-none rounded-lg'
                                        type={personalInfo.showPassword ? 'text' : 'password'}
                                        placeholder='Enter the Password'
                                        value={personalInfo.password}
                                        onChange={(e) => handleInputChange('password', e.target.value)}
                                    />
                                    <FontAwesomeIcon
                                        icon={personalInfo.showPassword ? faEye : faEyeSlash}
                                        className='w-[5%] px-2 cursor-pointer'
                                        onClick={() => togglePasswordVisibility('showPassword')}
                                    />
                                </div>
                                <div className='flex items-center rounded-lg border border-white/50 bg-white/5'>
                                    <input
                                        className='w-[100%] py-2 2xl:py-4 px-3 bg-transparent outline-none rounded-lg'
                                        placeholder='Referral code'
                                    />
                                </div>
                            </div>
                            <div className='space-y-5'>
                                <div className='flex items-center rounded-lg border border-white/50 bg-white/5'>
                                    <input
                                        className='w-[95%] py-2 2xl:py-4 px-3 bg-transparent outline-none rounded-lg'
                                        type='email'
                                        placeholder='Enter Your Email'
                                        value={personalInfo.email}
                                        onChange={(e) => handleInputChange('email', e.target.value)}
                                    />
                                    <FontAwesomeIcon icon={faEnvelope} className='w-[5%] px-2' />
                                </div>
                                <div className='flex items-center 2xl:py-2 rounded-lg border border-white/50 bg-white/5'>
                                    <Select
                                        options={StateList}
                                        placeholder='State'
                                        styles={customStyles}
                                        value={personalInfo.state}
                                        onChange={(selectedOption) =>
                                            handleInputChange('state', selectedOption)
                                        }
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.name}
                                        className='w-[100%]'
                                    />
                                </div>
                                <div className='flex items-center rounded-lg border border-white/50 bg-white/5'>
                                    <input
                                        type="date"
                                        className='w-[100%] py-2 2xl:py-4 px-3 bg-transparent outline-none rounded-lg'
                                        value={personalInfo.dob}
                                        onChange={(e) => handleInputChange('dob', e.target.value)}
                                    />
                                </div>

                                <div className='flex items-center rounded-lg border border-white/50 bg-white/5'>
                                    <input
                                        className='w-[95%] py-2 2xl:py-4 px-3 bg-transparent outline-none rounded-lg'
                                        type={personalInfo.showConfirmPassword ? 'text' : 'password'}
                                        placeholder='Confirm Password'
                                        value={personalInfo.confirmPassword}
                                        onChange={(e) => handleInputChange('confirmPassword', e.target.value)}
                                    />
                                    <FontAwesomeIcon
                                        icon={personalInfo.showConfirmPassword ? faEye : faEyeSlash}
                                        className='w-[5%] px-2 cursor-pointer'
                                        onClick={() => togglePasswordVisibility('showConfirmPassword')}
                                    />
                                </div>
                                <div className='flex items-center gap-3'>
                                    <p className='text-[#77BBAC]'>Optional</p>
                                </div>
                            </div>
                        </div>
                        <div className='mt-5 flex justify-center'>
                            <button className='bg-gradient-to-r from-[#0EA4A9] to-[#5973CC] w-[30%] rounded-2xl py-2 2xl:py-4 font-semibold'>Submit</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
