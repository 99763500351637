import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import { SERVER_URL } from "../../../../Config/config";

export const fetchUser = createAsyncThunk("user/fetchUser", async (email, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${SERVER_URL}/api/v1/user/${email}`);
    return response.data;
  } catch (error) {
    console.error("❌ Error fetching user data:", error);
    toast.error("Failed to fetch user data. Please try again.");
    return rejectWithValue(error.response?.data || "An error occurred.");
  }
});

// Update user profile
export const updateUser = createAsyncThunk("user/updateUser", async (user, { rejectWithValue }) => {
  const toastId = toast.loading("Updating profile... ⏳"); // ✅ Add toast loader

  try {
    const response = await axios.post(`${SERVER_URL}/api/v1/update-profile/${user.email}`, user);
    
    toast.success("User profile updated successfully! 🎉", { id: toastId });
    
    setTimeout(() => {
      window.location.reload(); 
    }, 1000);

    return response.data;
  } catch (error) {
    console.error("❌ Error updating user data:", error);
    toast.error("Failed to update user profile. Please try again.", { id: toastId });
    return rejectWithValue(error.response?.data || "An error occurred.");
  }
});

  const userSlice = createSlice({
    name: 'user',
    initialState: {
      user: null,
      loading: false,
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchUser.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchUser.fulfilled, (state, action) => {
          state.loading = false;
          state.user = action.payload;
        })
        .addCase(fetchUser.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        })
        .addCase(updateUser.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(updateUser.fulfilled, (state, action) => {
          state.loading = false;
          state.user = action.payload;  // You can update the user state with the updated profile
        })
        .addCase(updateUser.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
    },
  });

  

export default userSlice.reducer;
