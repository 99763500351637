import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from '../../../../../store/axiosInstance';
import { SERVER_URL } from '../../../../../Config/config';




export const fetchCourses = createAsyncThunk('courses/fetchCourses', async (credentials, { rejectWithValue }) => {
    try {
    //   const response = await axiosInstance.get(`/api/v1/course/all-courses`, credentials, {
    //     withCredentials: true, 
    //   });
      const response = await axiosInstance.get(`${SERVER_URL}/api/v1/course/all-courses`);
      console.log("response",response)
      return response.data; 
    } catch (error) {
      console.log("error",error)
      return rejectWithValue(error.response?.data);
    }
  });

const coursesSlice = createSlice({
    name: 'courses',
    initialState: {
        courses: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCourses.pending, (state) => {
                state.loading = true;
                state.error = null;

            })
            .addCase(fetchCourses.fulfilled, (state, action) => {
                state.loading = false;
                state.courses = action.payload;
            })
            .addCase(fetchCourses.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default coursesSlice.reducer;
