
// import React from "react";

// const ProtectedRoute = ({ element }) => {
//   const email = localStorage.getItem("edu_email");

//   console.log("email:", email);

//   if (!email) {
//     // Redirect to external URL
//     window.location.href = "https://ex.d5art.com/SeekerRegistration";
//     return null; // Return nothing since the redirect will happen
//   }

//   return element;
// };

// export default ProtectedRoute;



// import { Navigate } from "react-router-dom";
// import { useSelector } from "react-redux";

// const ProtectedRoute = ({ children, role }) => {
//   const cxoUser = useSelector((state) => state.cxoAuth.user);
//   const normalUser = localStorage.getItem("edu_email"); 

//   if (role === "cxo" && !cxoUser?.email) {
//     return <Navigate to="/CXO/login" replace />;
//   }

//   if (role === "user" && !normalUser) {
//     window.location.href = "https://ex.d5art.com/SeekerRegistration";
//     return null;
//   }

//   if (role === "admin" && cxoUser?.role !== "admin") {
//     return <Navigate to="/admin/login" replace />;
//   }

//   return children;
// };

// export default ProtectedRoute;







import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
// import UnauthorizedPage from "./UnauthorizedPage.js";

const ProtectedRoute = ({ children, allowedRoles }) => {
  const userRole = useSelector((state) => state?.auth?.user?.role);
  const email = useSelector((state) => state?.auth?.user?.email);
  const location = useLocation();
  const navigate = useNavigate();

 // const isAuthenticated = userRole && email;
  const isAuthenticated = Boolean(userRole && email);


  const isAuthorized = allowedRoles.includes(userRole);

  const redirectPath = userRole ? `/${userRole}-login` : "/seeker-login";

  useEffect(() => {
    if (!isAuthorized && isAuthenticated) {
      toast.error("You are not authorized to access this page!");
    }
  }, [isAuthorized, isAuthenticated]);

  if (!isAuthenticated) {
    localStorage.setItem("redirectAfterLogin", location.pathname + location.search);
    return <Navigate to={redirectPath} />;
  }

  // if (!isAuthorized) {
  //   return <UnauthorizedPage />;
  // }

  if (!isAuthorized) {
    return <Navigate to={`/${userRole}-login`} />;
  }

  return children;
};

export default ProtectedRoute;
