import React from 'react';

export default function Optionbg({questionno, text ,onClick, className}) {
    return (
        <div className='relative flex justify-center items-center' onClick={onClick}>
            {/* Container to constrain the text */}
            <div 
                className={`absolute px-4 text-center max-w-full overflow-hidden text-ellipsis whitespace-wrap ${className}`} 
                style={{ width: '90%', top: '50%', transform: 'translateY(-50%)' }}
            >
                <p className='text-xs 2xl:text-base'><span className='mr-2'>
                    {/* {questionno}. */}
                    </span> {text}</p>
            </div>
            <svg width="full" height="100" viewBox="0 0 573 124" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M568.587 13.4921L561.19 6.29575H532.276L80.5755 6L60.9956 13.1964L9 110.791L17.0495 115.918H495.271L514.633 111.482L568.587 13.4921Z" fill="url(#paint0_linear_317_151)"/>
            <path d="M568.587 13.4921L561.19 6.29575H532.276L80.5755 6L60.9956 13.1964L9 110.791L17.0495 115.918H495.271L514.633 111.482L568.587 13.4921Z" stroke="url(#paint1_linear_317_151)" stroke-width="2"/>
            <path d="M568.587 13.4921L561.19 6.29575H532.276L80.5755 6L60.9956 13.1964L9 110.791L17.0495 115.918H495.271L514.633 111.482L568.587 13.4921Z" stroke="#51CBFF" stroke-width="2"/>
            <g filter="url(#filter0_dii_317_151)">
            <path d="M56.9062 21.4451L61.9646 13.5245L71.6415 9.96028L82.4181 6L94.0744 6" stroke="url(#paint2_linear_317_151)" stroke-width="3" stroke-linecap="round"/>
            <path d="M56.9062 21.4451L61.9646 13.5245L71.6415 9.96028L82.4181 6L94.0744 6" stroke="#51CBFF" stroke-width="3" stroke-linecap="round"/>
            </g>
            <g filter="url(#filter1_dii_317_151)">
            <path d="M47.9092 116.509L18.5219 116.509L10.4676 111.468L13.0799 106.723L38.5488 57.4982" stroke="url(#paint3_linear_317_151)" stroke-width="3" stroke-linecap="round"/>
            <path d="M47.9092 116.509L18.5219 116.509L10.4676 111.468L13.0799 106.723L38.5488 57.4982" stroke="#51CBFF" stroke-width="3" stroke-linecap="round"/>
            </g>
            <g filter="url(#filter2_dii_317_151)">
            <path d="M565.923 19.3856L569 13.3922L565.923 10.3464L561.748 6.51465L545.047 6.51465" stroke="url(#paint4_linear_317_151)" stroke-width="3" stroke-linecap="round"/>
            <path d="M565.923 19.3856L569 13.3922L565.923 10.3464L561.748 6.51465L545.047 6.51465" stroke="#51CBFF" stroke-width="3" stroke-linecap="round"/>
            </g>
            <defs>
            <filter id="filter0_dii_317_151" x="47.4062" y="0.5" width="50.168" height="28.4453" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dx="-3" dy="1"/>
            <feGaussianBlur stdDeviation="2.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.317647 0 0 0 0 0.796078 0 0 0 0 1 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_317_151"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_317_151" result="shape"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dx="1" dy="2"/>
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.317647 0 0 0 0 0.796078 0 0 0 0 1 0 0 0 1 0"/>
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_317_151"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="7.5"/>
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.317647 0 0 0 0 0.796078 0 0 0 0 1 0 0 0 1 0"/>
            <feBlend mode="normal" in2="effect2_innerShadow_317_151" result="effect3_innerShadow_317_151"/>
            </filter>
            <filter id="filter1_dii_317_151" x="0.507812" y="52.0713" width="50.9727" height="71.8633" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dx="-3" dy="1"/>
            <feGaussianBlur stdDeviation="2.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.317647 0 0 0 0 0.796078 0 0 0 0 1 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_317_151"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_317_151" result="shape"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dx="1" dy="2"/>
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.317647 0 0 0 0 0.796078 0 0 0 0 1 0 0 0 1 0"/>
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_317_151"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="7.5"/>
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.317647 0 0 0 0 0.796078 0 0 0 0 1 0 0 0 1 0"/>
            <feBlend mode="normal" in2="effect2_innerShadow_317_151" result="effect3_innerShadow_317_151"/>
            </filter>
            <filter id="filter2_dii_317_151" x="535.547" y="1.01465" width="37.2891" height="25.8711" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dx="-3" dy="1"/>
            <feGaussianBlur stdDeviation="2.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.317647 0 0 0 0 0.796078 0 0 0 0 1 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_317_151"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_317_151" result="shape"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dx="1" dy="2"/>
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.317647 0 0 0 0 0.796078 0 0 0 0 1 0 0 0 1 0"/>
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow_317_151"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="7.5"/>
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.317647 0 0 0 0 0.796078 0 0 0 0 1 0 0 0 1 0"/>
            <feBlend mode="normal" in2="effect2_innerShadow_317_151" result="effect3_innerShadow_317_151"/>
            </filter>
            <linearGradient id="paint0_linear_317_151" x1="-8.98672" y1="-27.2221" x2="606.78" y2="35.5968" gradientUnits="userSpaceOnUse">
            <stop offset="0.00737362" stop-color="#011427"/>
            <stop offset="1" stop-color="#1F82DC"/>
            </linearGradient>
            <linearGradient id="paint1_linear_317_151" x1="45.1434" y1="-7.01859" x2="45.1434" y2="61.0185" gradientUnits="userSpaceOnUse">
            <stop stop-color="#51CBFF"/>
            <stop offset="1" stop-opacity="0"/>
            </linearGradient>
            <linearGradient id="paint2_linear_317_151" x1="59.3069" y1="4.17069" x2="59.3069" y2="13.7309" gradientUnits="userSpaceOnUse">
            <stop stop-color="#51CBFF"/>
            <stop offset="1" stop-opacity="0"/>
            </linearGradient>
            <linearGradient id="paint3_linear_317_151" x1="5.74661" y1="111.726" x2="27.526" y2="119.017" gradientUnits="userSpaceOnUse">
            <stop stop-color="#51CBFF"/>
            <stop offset="1" stop-opacity="0"/>
            </linearGradient>
            <linearGradient id="paint4_linear_317_151" x1="567.453" y1="4.99022" x2="567.453" y2="12.9571" gradientUnits="userSpaceOnUse">
            <stop stop-color="#51CBFF"/>
            <stop offset="1" stop-opacity="0"/>
            </linearGradient>
            </defs>
            </svg>
            
            </div>
            
      
    );
}
