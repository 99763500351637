import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import AppRoutes from './Routes/AppRoutes';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store/store';
import ReactGA from "react-ga4";
import { G_TRACKING_ID, WALLET_PROJECT_ID } from "./Config/config";

import { createAppKit } from '@reown/appkit/react'
import { Ethers5Adapter } from '@reown/appkit-adapter-ethers5'
import { mainnet, arbitrum } from '@reown/appkit/networks'


const App = () => {
  // console.log("%c Welcome to D5Art ", "font-weight: bold; font-size: 20px; color: red;");

  useEffect(() => {
    ReactGA.initialize(G_TRACKING_ID);

    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "D5art"
    });
  }, []);


  const projectId = WALLET_PROJECT_ID;
  console.log("WALLET_PROJECT_ID",WALLET_PROJECT_ID ,G_TRACKING_ID)

const metadata = {
  name: 'D5art',
  description: 'Welcome to D5art',
  url: 'https://d5art.com', 
  icons: ['https://d5art.com/assets/Logo/d5artWhite.png']
}



createAppKit({
  adapters: [new Ethers5Adapter()],
  metadata: metadata,
  networks: [mainnet, arbitrum],
  projectId,
  socials: false,
  features: {
    analytics: true 
  }
})


  return (
    <React.Fragment>
      <AppRoutes />
      <Toaster />
    </React.Fragment>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
console.log = () => {};

root.render(
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </BrowserRouter>
);
