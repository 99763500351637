import React from 'react'

export default function Blueblur() {
    return (
        <svg width="full" height="744" viewBox="0 0 1920 744" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_f_77_363)">
                <ellipse cx="948" cy="372" rx="864" ry="156" fill="#51CBFF" fill-opacity="0.51" />
            </g>
            <defs>
                <filter id="filter0_f_77_363" x="-132" y="0" width="2160" height="744" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="108" result="effect1_foregroundBlur_77_363" />
                </filter>
            </defs>
        </svg>
    )
}
