// import React from 'react'
// import Header from '../../../Layout/Header';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

// export default function Assessment() {
//     const questions = [
//         {
//           text: "What is your favorite color?",
//           options: ["Red", "Blue", "Green", "Yellow"],
//         },
//         {
//           text: "What is your favorite animal?",
//           options: ["Dog", "Cat", "Bird", "Fish"],
//         },
//         {
//           text: "What is your favorite food?",
//           options: ["Pizza", "Burger", "Pasta", "Sushi"],
//         },
//       ];

//     return (
//         <div className='bg-[#0F0F0F] bg-Fabcbg bg-cover bg-center'>
//         <Header/>
//         <button className='mx-[5%] text-white flex items-center'><FontAwesomeIcon icon={faChevronLeft} className='text-sm mr-2'/>Back</button>
//             <div className=' min-h-screen pb-10'>
//                 <div className='text-white w-[90%] mx-auto'>
//                     <div className='text-center fade-in'>
//                         <div className='flex justify-center'>
//                             <h1 className='bg-gradient-to-r from-[#31989B] to-[#4A5CA4] text-transparent bg-clip-text text-3xl font-bold'>Events</h1>
//                         </div>
//                         <h2 className='font-bold text-xl mt-2'>Jordon Skill Summit</h2>
//                         <h3 className='font-bold mt-5'>Welcome to D5art holland Assessment</h3>
//                         <p className='text-sm 2xl:text-base lg:w-[75%] mx-auto mt-2'>Discover your unique personality and natural abilities with the Holland Assessment. Explore your personal interests and gain valuable insights to better understand yourself. Start your journey now!</p>
//                     </div>
//                     <div className='mt-7 bg-white/10 rounded-2xl lg:w-[90%] mx-auto px-4 py-5 lg:p-10 fade-in'>
//                         {questions.map((question, index) => (
//                             <div key={index} className="mb-8">
//                                 <h2 className="text-lg font-semibold mb-4">{`${index + 1}. ${question.text}`}</h2>
//                                 <div className="space-y-4 pl-5">
//                                     {question.options.map((option, optionIndex) => (
//                                         <label key={optionIndex} className="flex items-center space-x-3">
//                                             <input
//                                                 type="radio"
//                                                 name={`question${index}`}
//                                                 className="w-4 h-4 text-blue-600 bg-gray-700 border-gray-600 rounded-full"
//                                             />
//                                             <span>{option}</span>
//                                         </label>
//                                     ))}
//                                 </div>
//                             </div>
//                         ))}
//                         <div className='flex justify-center'>
//                         <button className='bg-[#5973CC] px-3 py-1 rounded-md'>Submit my response</button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }



















import React, { useState, useEffect } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { NavLink, useNavigate } from 'react-router-dom';
// import '../../../Style/App.css'
import Nextbutton from '../Style/Nextbutton';
import Optionbg from '../Style/Optionbg';
import Blueblur from '../Style/Blueblur';
import { SERVER_URL } from '../../../../../../Config/config';
import { useSelector } from 'react-redux';
import axiosInstance from '../../../../../../store/axiosInstance';


const Loading = () => (
    <div className="flex items-center justify-center h-screen bg-black">
      <div>
        <div className="h-[8rem] w-[12.5rem]">
          <img src="/assets/Loader/profile_loader.gif" className="w-[75%] mx-auto" alt="Loading..." />
        </div>
        <p className="text-center font-bricolagegrotesque text-sky-300">
          Please wait Loading...
        </p>
      </div>
    </div>
  );


export default function Assessment() {

    const [questions, setQuestions] = useState([]);
    const [responses, setResponses] = useState({});
    const [result, setResult] = useState(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);

    const [completedStep3, setCompletedStep3] = useState(false);
    const email = useSelector((state) => state?.auth?.user?.email);
    const [loading, setLoading] = useState(true);


    const navigate = useNavigate();



    useEffect(() => {
        setLoading(true); 
        axiosInstance
            .get(`${SERVER_URL}/api/v1/holland/holland-assessment/questions`)
            .then(response => {
                 if (response.data?.status === 409) {
                    navigate("/holland/assessment-result");
                 } else {
                    setQuestions(response.data);    
                 }
            })
            .catch(error => {
                console.error("Error fetching questions:", error);
                toast.error("Failed to load questions. Please try again.");
            })
            .finally(() => {
                setLoading(false); 
              });
    }, [navigate]); 

    const handleNext = () => {
        if (selectedOption === null) {
            toast.error("Please choose one option");
            return;
          }

      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
        setSelectedOption(null);
      }
    };


    
    const handlePrevious = () => {
        if (currentQuestionIndex < questions.length - 1) {
          setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
          setSelectedOption(null);
        }
      };
  

    const handleOptionClick = (idx) => {
      setSelectedOption(idx); 
      const parsedOptions = JSON.parse(currentQuestion.options);
      console.log("responses", responses);
      console.log("idx", idx);
      console.log("currentQuestion", currentQuestion);
      console.log("parsedOptions", parsedOptions);
  
      setResponses({
          ...responses,
          [currentQuestion.id]: parsedOptions[idx] 
      });
  };
  console.log("responses", responses);


  const currentQuestion = questions[currentQuestionIndex];
  const isLastQuestion = currentQuestionIndex === questions.length - 1;
  console.log("isLastQuestion",isLastQuestion)
  
    const handleSubmit = () => {
        const unansweredQuestions = questions.filter((q) => !responses[q.id]);
        if (unansweredQuestions.length > 0) {
            toast.error('Please answer all questions before submitting.');
            return;
        }
        const toastId = toast.loading('Your Assessment is submitting...', { icon: '⌛' });
        const formattedResponses = Object.entries(responses).map(([questionId, answer]) => ({
            questionId: Number(questionId),
            answer,
        }));

        console.log("formattedResponses",formattedResponses)
        axiosInstance.post(`${SERVER_URL}/api/v1/holland/holland-assessment/answer-submit`, { email, responses: formattedResponses })
            // .then(() => {https://meet.google.com/rgn-jcdh-oqd
            //     return axios.post('http://localhost:4000/api/jordan-answer-calculate', { email });
            // })
            .then(response => {
                setResult(response.data);
                toast.success('Assessment is successfully completed!', {
                    id: toastId,
                });
                // setCompletedStep3(true)

                setTimeout(() => {
                    window.location.href = '/holland/assessment-result';
                }, 1000);
            })
            .catch(error => {
                if (error.response && error.response.status === 409) {
                    toast.error('You already submitted the assessment.', {
                        id: toastId,
                    });
                    // setTimeout(() => {
                    //     window.location.href = '/jordan-event';
                    // }, 2000);
                } else {
                    console.error('Submission error:', error);
                    toast.error('Something went wrong. Please try again.', {
                        id: toastId,
                    });
                }
            });
    };


    const splitOptions = (options) => {
      const half = Math.ceil(options.length / 2);
      return {
        leftColumn: options.slice(0, half),
        rightColumn: options.slice(half),
      };
    };
    
    const [active, setActive] = useState('')


    useEffect(() => {
      if (currentQuestion && currentQuestion.level) {
          setActive(currentQuestion.level);
      }
  }, [currentQuestion]);

  console.log("active",active,currentQuestion)

    const [Instruction, setInstruction] = useState(true);

    const handleBackClick = () => {
        if (window.history.length > 1) {
          window.history.back(); 
        } else {
          window.location.href = '/';
        }
      };

      if (loading) return <Loading />;
    return (
        <div className="bg-black bg-Assessmentgif bg-cover bg-center min-h-screen lg:h-screen overflow-hidden font-goldman flex flex-col">
                        <button      
                    onClick={handleBackClick}
                    className="mx-[5%] w-24 cursor-pointer text-white flex items-center z-50 px-2 py-5">
                  <FontAwesomeIcon icon={faChevronLeft} className="text-sm mr-2" />
                        Back
                     
                    </button>
             <Toaster />
            <div className='bg-gradient-to-r from-[#0E121E] via-[#1F77D25E] to-[#0E121E] h-[10vh] grid place-content-center fade-in'>
                <img src='/assets/Logo/d5artWhite.png' className='w-24 2xl:w-32'></img>
            </div>

            <div className='bg-black/80 h-full flex flex-1 items-center justify-center bg-cover bg-center bg-no-repeat'>
                <div className="mt-5 w-[90%] mx-auto">
                   
                  
                    <div className='text-white relative'>
                   
                        <div className='relative' style={{ zIndex: 1 }}>
                            <div className='grid lg:grid-cols-3'>
                                <div>
                                    <h1 className='text-2xl text-custom-shadow'>LEVEL {currentQuestion?.level}</h1>
                                    <div className='mt-3 p-[2px] rounded-xl bg-moreblack'>
                                        <div className='bg-black rounded-xl p-5'>
                                             <p>{currentQuestion?.category}</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <img src='/assets/Mascots/useravatar.svg' className='w-52 2xl:w-64 mx-auto'></img>
                                </div>
                                <div>
                                    <div className='w-[100%] md:w-[75%] lg:w-[100%] mx-auto rounded-xl bg-gradient-to-l from-[#51CBFF] to-[#414141] p-[2px]'>
                                        <div className='bg-gradient-to-b from-black to-[#0E222b] to-90% rounded-xl p-5'>
                                            <h1 className='flex justify-center items-center'>GROWTH MAP <img src='/assets/HollandAssessment/Growth.svg' className='w-7'></img></h1>
                                            <div className="mt-5 flex items-center justify-between relative">
                                                {[...Array(6)].map((_, index) => {
                                                    const isEven = (index + 1) % 2 === 0;
                                                    const level = `L${index + 1}`;
                                                    return (
                                                        <div key={index} className={`relative ${isEven ? 'mt-14' : ''}`}>
                                                            <img
                                                                src="/assets/Mascots/useravatar.svg"
                                                                style={{ zIndex: 2 }}
                                                                className={`absolute left-1/2 transform -translate-x-1/2 ${active === level ? (level === 'L6' ? 'w-12 -top-12 ' : '-top-8 w-8') : 'hidden'}`}
                                                            />
                                                            {level === 'L6' && (
                                                                <img
                                                                    src="/assets/Others/flag.svg"
                                                                    className={`absolute -top-16 left-[10%] transform ${active === level ? 'w-10' : 'hidden'}`}
                                                                />
                                                            )}
                                                            <div
                                                                className={`bg-[#001641] border rounded-full grid place-content-center mx-auto ${active === level
                                                                    ? 'w-14 2xl:w-[72px] h-14 2xl:h-[72px] border-[#FFBA00BF] text-2xl'
                                                                    : 'w-10 2xl:w-14 h-10 2xl:h-14 border-[#35FDFF52] text-lg'
                                                                    }`}
                                                            >
                                                                {index + 1}
                                                            </div>
                                                            {index < 5 && (
                                                                <svg
                                                                    className={`absolute ${isEven ? 'bottom-0 left-10' : 'top-0 left-10'}`}
                                                                    width="50"
                                                                    height="60"
                                                                    viewBox="0 0 50 20"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d={
                                                                            isEven
                                                                                ? 'M0 10 Q25 40 50 10'
                                                                                : 'M0 10 Q25 -20 50 10'
                                                                        }
                                                                        stroke="#85BFCA"
                                                                        strokeWidth="2"
                                                                        strokeDasharray="4 4"
                                                                    />
                                                                </svg>
                                                            )}
                                                        </div>
                                                    );
                                                })}

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*Question in mobile responsive*/}
                            <div className='block lg:hidden w-full mt-7 lg:mt-0'>
                                <div className='w-full text-black border-[3px] border-[#51CBFF] inner-shadow-black rounded-xl bg-[#51CBFF] p-5 pt-0'>
                                    <div className='flex justify-center items-center relative'>
                                        <h1 className='text-white absolute text-lg'>
                                        Question - {currentQuestionIndex + 1}
                                          </h1>
                                        <svg width="240" height="38" viewBox="0 0 267 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0H267L237 38H34L0 0Z" fill="#0B9DDC" />
                                        </svg>
                                    </div>
                                    <h2 className="text-lg text-center mt-2">
                                      {currentQuestion?.question_text || "Loading..."}
                                    </h2>
                                </div>
                            </div>

                            <div className='flex flex-col lg:flex-row items-center gap-0 lg:gap-7 lg:-translate-y-3 '>
                            <div>
                            {currentQuestion && (() => {
                                  const options = JSON.parse(currentQuestion.options);
                                  const cleanedOptions = options.map(option =>
                                    option.replace(/\s*\([^)]*\)/g, "")
                                );
                                  const { leftColumn, rightColumn } = splitOptions(cleanedOptions);

                                  return (
                                      <>
                                          {/* Left Column - Displayed in mobile and on the left side in large screens */}
                                          {leftColumn.map((option, idx) => (
                                              <Optionbg
                                                  key={idx}
                                                  questionno={idx + 1}
                                                  text={option}
                                                  onClick={() => handleOptionClick(idx)}
                                                  className={`cursor-pointer ${selectedOption === idx ? "text-green-500" : ""}`}
                                              />
                                          ))}
                                      </>
                                  );
                              })()}
                            </div>
                                <div className='space-y-5 hidden lg:block w-1/3'>
                                    <div className='text-black border-[3px] border-[#51CBFF] inner-shadow-black rounded-xl bg-[#51CBFF] p-5 pt-0'>
                                        <div className='flex justify-center items-center relative'>
                                            <h1 className='text-white absolute text-lg'>
                                            Question - {currentQuestionIndex + 1}
                                              </h1>
                                            <svg width="240" height="38" viewBox="0 0 267 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 0H267L237 38H34L0 0Z" fill="#0B9DDC" />
                                            </svg>
                                        </div>
                                        <h2 className="text-lg text-center mt-2"> 
                                            {currentQuestion?.question_text || "Loading..."}
                                         </h2>
                                    </div>
                                    <div>
                                    <div className='w-full'>
                                        {/* <div className='w-1/2'>
                                   
                                        {isLastQuestion ? (
                                      <Nextbutton  text="Previous" />
                                    ) : (
                                      <Nextbutton onClick={handlePrevious} text="Previous" />
                                    )}
                                    </div>
                                    <div className='w-1/2'> */}
                                    {isLastQuestion ? (
                                      <div className='flex justify-center'><Nextbutton onClick={handleSubmit} text="Submit" /></div>
                                    ) : (
                                        <div className='flex justify-center'><Nextbutton onClick={handleNext} text="Next" /></div>
                                    )}
                                    {/* </div> */}
                                    </div>
                                    </div>
                                </div>
                                <div>
                                {currentQuestion && (() => {
                                const options = JSON.parse(currentQuestion.options);
                                const cleanedOptions = options.map(option =>
                                option.replace(/\s*\([^)]*\)/g, "")
                                );
                                const { leftColumn, rightColumn } = splitOptions(cleanedOptions);
                                return (
                                <>
                            {/* Right Column - Displayed in mobile and on the right side in large screens */}
                            {rightColumn.map((option, idx) => (
                                <Optionbg
                                    key={idx + leftColumn.length}
                                    questionno={idx + leftColumn.length + 1}
                                    text={option}
                                    onClick={() => handleOptionClick(idx + leftColumn.length)}
                                    className={`cursor-pointer ${selectedOption === idx + leftColumn.length ? "text-green-500 " : ""}`}
                                />
                            ))}
                        </>
                    );
                })()}
                   </div>
                            </div>
                            {/*Next button in mobile responsive*/}
                            <div className='block lg:hidden w-full pb-10 lg:pb-0 '>
                                <div className='flex justify-center'>
                                    <div className='w-[75%] md:w-[50%]'>
                                    {isLastQuestion ? (
                                      <Nextbutton onClick={handleSubmit} text="Submit" />
                                    ) : (
                                      <Nextbutton onClick={handleNext} text="Next" />
                                    )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='absolute z-0 -translate-y-[50%] top-[50%]'>
                            <Blueblur />
                        </div>
                    </div>

                    {/*
                    <div className="text-white w-[90%] mx-auto">

                        <div className="mt-7 bg-white/10 rounded-2xl lg:w-[90%] mx-auto px-4 py-5 lg:p-10 fade-in">
                            {questions.map((question, index) => (
                                <div key={index} className="mb-8">
                                    <h2 className="text-lg font-semibold mb-4">{`${index + 1}. ${question.question_text}`}</h2>
                                    <div className="space-y-4 pl-5">
                                        {(() => {
                                            try {
                                                const options = question.options ? JSON.parse(question.options) : [];
                                                return options.map((option, optionIndex) => (
                                                    <label key={optionIndex} className="flex items-center space-x-3">
                                                        <input
                                                            type="radio"
                                                            name={question.id}
                                                            className="w-4 h-4 text-blue-600 bg-gray-700 border-gray-600 rounded-full"
                                                            value={option}
                                                            onChange={() =>
                                                                setResponses({
                                                                    ...responses,
                                                                    [question.id]: option,
                                                                })
                                                            }
                                                        />{' '}
                                                        <span>{option}</span>
                                                    </label>
                                                ));
                                            } catch (error) {
                                                console.error('Invalid JSON for options', error);
                                                return <p>Options are unavailable</p>;
                                            }
                                        })()}
                                    </div>
                                </div>
                            ))}
                            <div className="flex justify-center">
                                <button
                                    className="bg-[#5973CC] px-3 py-1 rounded-md"
                                    onClick={handleSubmit}
                                >
                                    Submit my response
                                </button>
                            </div>
                        </div>
                    </div>
                    */}
                    {completedStep3 && (
                        <div className="fixed inset-0 bg-black/50 flex justify-center items-center ">
                            <div className=" w-[95%] mx-auto max-w-xl bg-[#1D1D1DCF] backdrop-blur rounded-2xl p-5">
                                <div className="w-full flex justify-around items-center">
                                    <img
                                        src="/assets/Others/confetti.svg"
                                        className="w-4/12 rotate-90 "
                                    />
                                    <img src="/assets/Others/confetti.svg" className="w-4/12" />
                                </div>

                                <p className="text-xs italic mt-3 text-center text-white">
                                    Thank you for completing the assessment! Your report is being
                                    generated and will be shared with you via email shortly
                                </p>
                                <div className="flex justify-center items-center mt-5">
                                    <NavLink to="/jordan-event">
                                        <div className="border border-white/50 p-2 rounded-br-3xl">
                                            <button
                                                onClick={() => setCompletedStep3(false)}
                                                className="relative inline-block px-6 py-3 text-white text-sm font-medium bg-gray-700 rounded-br-3xl font-dmsans"
                                            >
                                                Back to hackathon
                                            </button>
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {Instruction && (
                <div className='fixed inset-0 z-10 flex items-center justify-center bg-black/75 h-full py-10 '>
                    <div className='w-[90%] lg:w-[70%] p-5 bg-black rounded-xl h-full  overflow-hidden overflow-y-auto text-white fade-in'>
                        {/* <div className='flex justify-end'><FontAwesomeIcon icon={faXmarkCircle} onClick={() => setInstruction(false)} className='cursor-pointer'/></div> */}
                        <h1 className='text-center text-2xl font-bold'>Instructions</h1>
                        <ol class="space-y-4 lg:text-sm 2xl:text-base mt-10">
                                <li>
                                    <span class="font-semibold">1. Find a Comfortable Setting</span> <p className=' sm:block hidden  '>Choose a quiet and comfortable environment where you can concentrate without interruptions for the duration of the assessment.</p>
                                </li>
                                <li>
                                    <span class="font-semibold">2. Stay Relaxed and Authentic</span>  <p className=' sm:block hidden '>Answer honestly and authentically; there are no right or wrong answers. The assessment is designed to understand your natural tendencies and preferences.</p>
                                </li>
                                <li>
                                    <span class="font-semibold">3. Avoid Overthinking</span>  <p className=' sm:block hidden '>Respond to each question based on your first instinct rather than overanalyzing. This ensures the truest reflection of your personality.</p>
                                </li>

                                <li className='  '>
                                    <span class="font-semibold">4. Manage Your Time</span> 
                                    <p className=' sm:block hidden '>While there may not be strict time limits, ensure you allocate enough time to complete the assessment without feeling rushed.</p>
                                </li>
                                <li className='  '>
                                    <span class="font-semibold">5. Be Prepared and Focused</span> <p className=' sm:block hidden '>Make sure you’re well-rested and mentally prepared. Take a moment to relax before starting to ensure clarity and focus.</p>
                                </li>
                        </ol>
                        <div className='mt-10 flex items-center gap-2 text-sm font-poppins'>By clicking "Confirm," you agree to the terms and conditions.</div>
                        <div className='flex justify-center mt-10 md:mt-3'><button onClick={() => setInstruction(false)} className='px-5 py-1 bg-white/10 rounded-md'>Confirm</button></div>
                    </div>
                </div>
            )}
        </div>
    );
}




























///-===












// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import toast, { Toaster } from 'react-hot-toast';
// import Header from '../../../Layout/Header';
// import Footer from "../../../Layout/Footer";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
// import { NavLink, useNavigate } from 'react-router-dom';
// import { SERVER_URL } from '../../../../config';
// import '../../../Style/App.css'
// import Nextbutton from '../Style/Nextbutton';
// import Optionbg from '../Style/Optionbg';
// import Blueblur from '../Style/Blueblur';

// export default function Assessment() {
//     const [questions, setQuestions] = useState([]);
//     const [responses, setResponses] = useState({});
//     const [result, setResult] = useState(null);
//     const [completedStep3, setCompletedStep3] = useState(false);

//     const email = localStorage.getItem("edu_email");

// console.log("responses", responses)
//     const navigate = useNavigate();

//     useEffect(() => {
//         const checkAssessmentStatus = async () => {
//             try {
//                 const response = await axios.get(`${SERVER_URL}/api/jordan-assessment-check?email=${email}`);

//                 if (response.data.success && response.data.hasTakenAssessment) {

//                     toast.dismiss();

//                     toast.error("You have already completed the assessment", {
//                         duration: 3000,
//                     });

//                     setTimeout(() => {
//                         //navigate('/jordan-event'); 
//                     }, 3000);
//                 }
//             } catch (error) {
//                 console.error('Error checking assessment status:', error);
//             }
//         };

//         checkAssessmentStatus();
//     }, [email, navigate]);


//     useEffect(() => {
//         axios
//             .get(`${SERVER_URL}/api/jordan-question`)
//             .then(response => {
//                 setQuestions(response.data);
//             })
//             .catch(error => {
//                 console.error('Error fetching questions:', error);
//                 toast.error('Failed to load questions. Please try again.');
//             });
//     }, []);




//     const handleSubmit = () => {

//         const unansweredQuestions = questions.filter((q) => !responses[q.id]);

//         if (unansweredQuestions.length > 0) {
//             toast.error('Please answer all questions before submitting.');
//             return;
//         }


//         const toastId = toast.loading('Your Assessment is submitting...', { icon: 'âŒ›' });

//         const formattedResponses = Object.entries(responses).map(([questionId, answer]) => ({
//             questionId: Number(questionId),
//             answer,
//         }));

//         axios
//             .post(`${SERVER_URL}/api/jordan-answer-set`, { email, responses: formattedResponses })
//             // .then(() => {
//             //     return axios.post('http://localhost:4000/api/jordan-answer-calculate', { email });
//             // })
//             .then(response => {
//                 setResult(response.data);
//                 toast.success('Assessment is successfully completed!', {
//                     id: toastId,
//                 });
//                 setCompletedStep3(true)
//             })
//             .catch(error => {
//                 if (error.response && error.response.status === 409) {
//                     toast.error('You already submitted the assessment.', {
//                         id: toastId,
//                     });
//                     setTimeout(() => {
//                         window.location.href = '/jordan-event';
//                     }, 2000);
//                 } else {
//                     console.error('Submission error:', error);
//                     toast.error('Something went wrong. Please try again.', {
//                         id: toastId,
//                     });
//                 }
//             });
//     };

//     const [active, setactive] = useState('level6')


//     return (
//         <div className="bg-black bg-Assessmentgif bg-cover bg-center min-h-screen font-goldman ">
//             <div className='bg-gradient-to-r from-[#0E121E] via-[#1F77D25E] to-[#0E121E] h-[10vh] grid place-content-center'>
//                 <img src='/assets/logo/d5artWhite.png' className='w-24 2xl:w-32'></img>
//             </div>
//             <div className='bg-black/80 bg-cover bg-center bg-no-repeat'>
//                 <button className="mx-[5%] text-white flex items-center mt-3">
//                     <FontAwesomeIcon icon={faChevronLeft} className="text-sm mr-2" />
//                     Back
//                 </button>
//                 <div className="mt-5">
//                     <Toaster />
                 

//                     <div className="text-white w-[90%] mx-auto">

//                         <div className="mt-7 bg-white/10 rounded-2xl lg:w-[90%] mx-auto px-4 py-5 lg:p-10 fade-in">
//                             {questions.map((question, index) => (
//                                 <div key={index} className="mb-8">
//                                     <h2 className="text-lg font-semibold mb-4">{`${index + 1}. ${question.question_text}`}</h2>
//                                     <div className="space-y-4 pl-5">
//                                         {(() => {
//                                             try {
//                                                 const options = question.options ? JSON.parse(question.options) : [];
//                                                 return options.map((option, optionIndex) => (
//                                                     <label key={optionIndex} className="flex items-center space-x-3">
//                                                         <input
//                                                             type="radio"
//                                                             name={question.id}
//                                                             className="w-4 h-4 text-blue-600 bg-gray-700 border-gray-600 rounded-full"
//                                                             value={option}
//                                                             onChange={() =>
//                                                                 setResponses({
//                                                                     ...responses,
//                                                                     [question.id]: option,
//                                                                 })
//                                                             }
//                                                         />{' '}
//                                                         <span>{option}</span>
//                                                     </label>
//                                                 ));
//                                             } catch (error) {
//                                                 console.error('Invalid JSON for options', error);
//                                                 return <p>Options are unavailable</p>;
//                                             }
//                                         })()}
//                                     </div>
//                                 </div>
//                             ))}
//                             <div className="flex justify-center">
//                                 <button
//                                     className="bg-[#5973CC] px-3 py-1 rounded-md"
//                                     onClick={handleSubmit}
//                                 >
//                                     Submit my response
//                                 </button>
//                             </div>
//                         </div>
//                     </div>
              
//                     {completedStep3 && (
//                         <div className="fixed inset-0 bg-black/50 flex justify-center items-center ">
//                             <div className=" w-[95%] mx-auto max-w-xl bg-[#1D1D1DCF] backdrop-blur rounded-2xl p-5">
//                                 <div className="w-full flex justify-around items-center">
//                                     <img
//                                         src="/assets/Others/confetti.svg"
//                                         className="w-4/12 rotate-90 "
//                                     />
//                                     <img src="/assets/Others/confetti.svg" className="w-4/12" />
//                                 </div>

//                                 <p className="text-xs italic mt-3 text-center text-white">
//                                     Thank you for completing the assessment! Your report is being
//                                     generated and will be shared with you via email shortly
//                                 </p>
//                                 <div className="flex justify-center items-center mt-5">
//                                     <NavLink to="/jordan-event">
//                                         <div className="border border-white/50 p-2 rounded-br-3xl">
//                                             <button
//                                                 onClick={() => setCompletedStep3(false)}
//                                                 className="relative inline-block px-6 py-3 text-white text-sm font-medium bg-gray-700 rounded-br-3xl font-dmsans"
//                                             >
//                                                 Back to hackathon
//                                             </button>
//                                         </div>
//                                     </NavLink>
//                                 </div>
//                             </div>
//                         </div>
//                     )}
//                 </div>
//             </div>
//             <Footer />
//         </div>
//     );
// }